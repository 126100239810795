@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

* {
  box-sizing: border-box;
}
body {
  background: #1c8ef9 !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}
/* html{

  margin-top: 100px;
} */
.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);

  padding: 20px 10px 1px 10px;

  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  /* padding-bottom: 20px; */
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.signUp {
  padding-top: 100px;
  margin-top: 140px; /*for register heading here*/
  margin-bottom: 30px;
}
.signUpPersonnel {
  /* text-align: center; */
  padding-top: 13px;
  padding-bottom: 2px;
}
.btn-color {
  margin-right: 20px;
  margin-top: 10px;
}
.addNew {
  margin-top: 60px;
  padding: 10px 0 20px 0;
}
.userView {
  margin: 20px;
  padding-bottom: 20px;
}
/* .userViewType{
  display: inline-block !important;
  width: 50% !important;
} */
.services {
  padding: 8px;
}
.items {
  padding: 10px;
}
.btns {
  padding-top: 20px;
}
